import axiosClient from "../api/AxiosClient";
import { ILogin, ILoginResult } from "../interfaces/ILogin";

export const LoginAsync = async (Login:ILogin) =>
{
    var res = (await axiosClient.post<ILoginResult>(`/login`,Login));
    console.log(res);
    
    return res.data;
}
export const ChangePasswordAsync = async (req : any) =>
{
    var res = (await axiosClient.post(`/change-password`,req));    
    return res.data;
}
