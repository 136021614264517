import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LazyLogin = React.lazy(() => import('./index'));

const LoginWrapper = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const token = localStorage.getItem("token");
    if(token){
      navigate('/');
    }
  }, []);

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <LazyLogin />
    </React.Suspense>
  );
};

export default LoginWrapper;
