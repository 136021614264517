import axios, { AxiosResponse } from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../components/Authenticated/AuthContext';

const axiosClient = axios.create({
  baseURL: `https://api.duhocvkj.com.vn`,
  headers: {
    'Content-type': 'application/json',
  },
  withCredentials: false, // Sử dụng true để gửi cookie với yêu cầu từ trình duyệt
});

const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');

  if (!refreshToken) {
    return Promise.reject(new Error('No refresh token found.'));
  }

  try {
    const response = await axiosClient.post('/refresh-token', { refreshToken });
    const accessToken = response.data.accessToken;
    return accessToken;
  } catch (error) {
    return Promise.reject(error);
  }
};

axiosClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: any) => {
    const { response } = error;
    if (response && response.status === 401 && localStorage.getItem('token')) {
      try {
        // const accessToken = await refreshToken();
        error.config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
        return axios.request(error.config);
      } catch (refreshError) {
        console.error('Refresh token failed:', refreshError);
        return Promise.reject(refreshError);
      }
    } else if (response && response.status === 401 && !localStorage.getItem('token')) {
      const { handleUnauthorized } = useContext(AuthContext);
      handleUnauthorized();
    }

    const errorMessage = response?.data?.error_message ?? 'Error';
    console.log(errorMessage);
    return Promise.reject(error);
  }
);

export default axiosClient;
