// AuthContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';
import axiosClient from '../../api/AxiosClient';

interface AuthContextType {
  isAuth: boolean;
  handleUnauthorized: () => void;
  handleAuthorized: (token : string)=> void;
}

const defaultValue: AuthContextType = {
  isAuth: false,
  handleUnauthorized: () => {},
  handleAuthorized: (token : string)=> {}
};

export const AuthContext = createContext<AuthContextType>(defaultValue);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isAuth, setIsAuth] = useState<boolean>(false);

  const handleUnauthorized = () => {
    setIsAuth(false);
  };
  const handleAuthorized = (token : string) => {
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    setIsAuth(true);
  };

  return (
    <AuthContext.Provider value={{ isAuth, handleUnauthorized , handleAuthorized}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
