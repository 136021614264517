import React from "react";
import { Button, Layout, Menu } from "antd";
import { Link, NavLink, Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  UserOutlined,
} from '@ant-design/icons';

const { Sider } = Layout;
const { SubMenu } = Menu;

interface Props {
  collapsed: boolean;
  onToggleSidebar: () => void;
}

export const Sidebar = (props: Props) => {
  const navigate = useNavigate();
  const handleLogout = () =>{    
    localStorage.removeItem('token');
    navigate('/login');
  };
  // const location = useLocation();
  return (
    <Sider collapsible collapsed={props.collapsed} onCollapse={props.onToggleSidebar}>
      <div className="logo"></div>
      <Menu mode="inline" defaultSelectedKeys={['1']} defaultOpenKeys={['sub1']}>
        <SubMenu key="sub1" title="Dashboard" icon={<UserOutlined rev={undefined} />}>
          <Menu.Item key="1">
          {/* <Navigate to={'/formDashboard'} state={{ from: location }} replace />Biểu mẫu đăng kí1 */}
            <NavLink to="/formDashboard">Biểu mẫu đăng kí</NavLink>
          </Menu.Item>
          <Menu.Item key="2">
            <NavLink to="/contentDashboard">Nội dung</NavLink>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub2" title="Tài khoản" icon={<UserOutlined rev={undefined} />}>
          <Menu.Item key="5">
            <NavLink to="/change-password">Đổi mật khẩu</NavLink>
          </Menu.Item>
          <Menu.Item key="6" onClick={handleLogout}>
            Đăng xuất
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Sider>
  );
};
