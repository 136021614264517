import React, { useState, Suspense, useEffect } from 'react';
import { ConfigProvider, Layout } from 'antd';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Sidebar } from './components/layout/Sidebar';
import './App.css';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import LoginWrapper from './page/login/lazyloading';
import ChangePasswordPage from './page/changePassword/ChangePassword';

const PrivateRoute = React.lazy(() => import('./components/Authenticated/PrivateRoute'));
const FormDashboard = React.lazy(() => import('./page/form/index'));
const ContentDashboard = React.lazy(() => import('./page/content/index'));

const { Content, Footer } = Layout;

const App: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  }
  return (
    <ConfigProvider>
      <BrowserRouter>
        <Suspense fallback={<Loading3QuartersOutlined rev={undefined}></Loading3QuartersOutlined>}>
          <Routes>
          <Route path="/login" element={<LoginWrapper  />} />
          <Route element={<PrivateRoute />}>
          <Route path='/*' element = {<Layout style={{ minHeight: '100vh' }}>
                <Sidebar collapsed={collapsed} onToggleSidebar={toggleSidebar} />
                <Layout className="site-layout">
                  <Content style={{ margin: '0 12px' }}>
                    <div style={{ padding: 24, minHeight: 580 }}>
                      <Routes>
                        <Route element={<PrivateRoute />}>
                        <Route path="/" element={<FormDashboard />} />
                          <Route path="/formDashboard" element={<FormDashboard />} />
                          <Route path="/contentDashboard" element={<ContentDashboard />} />
                          <Route path="/change-password" element={<ChangePasswordPage />} />
                          {/* Các Route khác nếu có */}
                        </Route>
                        {/* <Route path="*" element={<Navigate to="/" />} /> */}
                      </Routes>
                    </div>
                  </Content>
                  <Footer style={{ textAlign: 'center' }}>VKJ ©2024</Footer>
                </Layout>
              </Layout>}>

          </Route>
          </Route>
            
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
